import { computed } from 'vue'
import get from 'lodash/get'

export function getSettingValueInPropsComputed(props, key) {
  return computed(() => {
    const setting = get(props, 'section.settings', []).find(s => s.key === key)
    return setting ? setting.value : null
  })
}

export default function useSection() {
  return {
    getSettingValueInPropsComputed,
  }
}
