<script setup>
import { defineProps, computed, ref } from 'vue'
import { BButton } from 'bootstrap-vue'
import get from 'lodash/get'
import useDeleteItem from '@/composables/useDeleieItem'
import SettingsForm from '@/modules/sites-section/site-store-section/pages/components/SettingsForm.vue'
import useBlocksStore from '../../store/useBlocksStore'

const { removeItemById, moveItem } = useBlocksStore()

const props = defineProps({
  section: {
    type: Object,
    required: true,
  },
})

const title = computed(() => get(props.section, 'title', 'Без названия'))
const active = computed(() => get(props.section, 'active', false))

const deleteHandler = () => {
  removeItemById(props.section.id)
}

const { deleteItem: deleteSection } = useDeleteItem({
  deleteHandler,
  getQuestionTextBeforeDeletion: () => `Вы уверены, что хотите удалить блок <strong>${title.value}</strong>?`,
  getTextAfterDeletion: () => `Блок ${title.value} успешно удален`,
})

// BEGIN Settings
const showSettings = ref(false)

const toggleSettings = () => {
  showSettings.value = !showSettings.value
}
// END Settings
</script>

<template>
  <div class="base-section">
    <div class="base-section__control">
      <div
        class="base-section__control__active-indicator"
        :class="{
          'active': active,
        }"
      />

      <div class="base-section__control__title">
        {{ title }}
      </div>
      <div
        class="base-section__control__right"
      >
        <b-button
          variant="flat-secondary"
          size="sm"
          class="btn-icon"
          @click="moveItem(section.id, 'down')"
        >
          <feather-icon icon="ChevronDownIcon" />
        </b-button>
        <b-button
          variant="flat-secondary"
          size="sm"
          class="btn-icon"
          @click="moveItem(section.id, 'up')"
        >
          <feather-icon icon="ChevronUpIcon" />
        </b-button>
        <b-button
          variant="flat-danger"
          size="sm"
          class="btn-icon"
          @click="deleteSection"
        >
          <feather-icon
            icon="Trash2Icon"
          />
        </b-button>
        <b-button
          :variant="showSettings ? 'flat-primary' : 'flat-secondary'"
          size="sm"
          class="btn-icon"
          @click="toggleSettings"
        >
          <feather-icon icon="EditIcon" />
        </b-button>
      </div>

    </div>
    <div class="base-section__preview">
      <slot />
    </div>
    <div
      v-if="showSettings"
      class="base-section__settings p-2"
    >
      <slot name="settings">
        <settings-form
          :section="section"
        />
      </slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
// Overrides user variable
@import '~@core/scss/base/components/include';

.base-section{
  margin: 5px 0;
  position: relative;
  &__preview{
    min-height: 52px;
    &:hover{

      box-shadow: inset 10px 10px 20px 1px $primary;
    }

  }

  &__control{
    display: flex;
    align-items: center;

    border-radius: 6px 6px 0 0;
    padding: 0 0 0 10px;
    border: 1px solid $border-color;
    background: var(--light);

    &__title{
      margin-right: auto;
      padding: 5px 10px;
      font-weight: bold;
    }

    &__active-indicator{
      width: 10px;
      height: 10px;
      background: $secondary;
      border-radius: 50%;
      &.active{
        background: $success;
      }
    }
  }
  &__settings{
    background: var(--light);
    border: 1px solid $border-color;
    border-radius: 0 0 6px 6px;
  }
}
</style>
