<script setup>
import { defineProps, computed } from 'vue'

import {
  BRow, BCol, BFormGroup, BFormCheckbox, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import get from 'lodash/get'
import useBlocksStore from '../store/useBlocksStore'

const { setSettingsItem, setActiveItem } = useBlocksStore()

const props = defineProps({
  section: {
    type: Object,
    required: true,
  },
})

const active = computed({
  get() {
    return get(props.section, 'active', false)
  },
  set(value) {
    setActiveItem(props.section.id, value)
  },
})

const settings = computed({
  get() {
    return get(props.section, 'settings', [])
  },
  set(value) {
    setSettingsItem(props.section.id, value)
  },
})

</script>

<template>
  <div class="a-setting-form">
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Видимость"
          :label-for="'is_active' + section.id"
        >
          <b-form-checkbox
            :id="'is_active' + section.id"
            v-model="active"
            switch
            :value="true"
            :unchecked-value="false"
          >
            <template v-if="active">
              Вкл
            </template>
            <template v-else>
              Выкл
            </template>
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col
        v-for="setting in settings"
        :key="`s-${section.id}-${setting.key}`"
        :cols="12"
        :md="get(setting, 'cols', 12)"
      >
        <b-form-group
          :label="setting.title"
          :label-for="`s-${section.id}-${setting.key}`"
          :description="setting.description"
        >
          <b-form-input
            v-if="setting.type === 'string'"
            :id="`s-${section.id}-${setting.key}`"
            v-model="setting.value"
            :placeholder="setting.placeholder"
          />
          <b-form-textarea
            v-else-if="setting.type === 'text'"
            :id="`s-${section.id}-${setting.key}`"
            v-model="setting.value"
            :placeholder="setting.placeholder"
          />
          <v-select
            v-else-if="setting.type === 'select'"
            :id="`s-select-${section.id}-${setting.key}`"
            v-model.trim="setting.value"
            :input-id="`s-${section.id}-${setting.key}`"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="setting.options"
            :placeholder="setting.placeholder"
            label="label"
            :reduce="val => val.value"
            :clearable="false"
            class="bg-white"
          />
          <b-form-textarea
            v-else-if="setting.type === 'md'"
            :id="`s-${section.id}-${setting.key}`"
            v-model="setting.value"
            :placeholder="setting.placeholder"
          />
          <pre v-else>{{ setting }}</pre>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped lang="scss">
.a-setting-form{

}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
